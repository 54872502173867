<template>
  <div class="background-img-4 flx flx-d-column fx-center h-100">
    <div class="title text">
      <h2 class="step">TUDO PRONTO</h2>
      <h2 class>FAÇA SEU DOWNLOAD</h2>
      <p>HORA DE BAIXAR SEUS VÍDEOS</p>
      <div>
        <img src="../../assets/img/all-ready.png" alt="upload" width="50%" />
      </div>

      <div class="container fx-center">
        <p class="style-input-p1">
          <span class="step">BAIXAR</span> NO CELULAR
        </p>
        <label class="switch">
          <input type="checkbox" />
          <span class="slider"></span>
        </label>
      </div>
      <!--
      <div class="container fx-center">
        <p class="style-input-p2">
          <span class="step">ENVIAR </span> PARA MEU E-MAIL
        </p>
        <label class="switch">
          <input type="checkbox" />
          <span class="slider"></span>
        </label>
        <input class="input-style" type="text" placeholder="SEU E-MAIL" />
      </div>
      -->
      <button class="btn-white" type="button" @click="finishNugget">Finalizar</button>
      <div class="mancha-step">
        <img src="../../assets/img/element-2.png" alt="imagem" />
      </div>
    </div>
  </div>
</template>


<style scoped>
.btn-white {
  width: 60%;
  padding: 0.3rem 0;
  background-color: #fff;
  color: #2b2b2b;
  font-weight: bold;
  font-size: 1rem;
  border: 0 solid #00000000;
  border-radius: 0.3rem;
  margin: 2.5%;
}

* {
  margin: 0;
  padding: 0;
}

.container {
  display: -webkit-inline-box;
}

.style-input-p1 {
  margin-right: 7rem;
}

.style-input-p2 {
  margin-right: 5rem;
}

h1 {
  margin-bottom: 40px;
  font-family: sans-serif;
}

.switch {
  position: relative;
  display: inline-block;
  width: 5.7rem;
  height: 2.6rem;
  margin: 2%;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2b2b2b;
  transition: 0.4s;
  border-radius: 34px;
}

.switch input {
  display: none;
}

.slider:before {
  position: absolute;
  content: "";
  height: 2rem;
  width: 2rem;
  left: 5px;
  bottom: 5px;
  background-color: #feea2f;
  transition: 0.4s;
  border-radius: 50px;
}

input:checked + .slider {
  background-color: #7e3f16;
}

input:checked + .slider:before {
  transform: translateX(50px);
}

.input-style {
  height: 2rem;
  width: 60%;
  border: 0 solid #00000000;
  margin: 2.5%;
  border-radius: 0.5rem;
  padding: 5px;
  outline: none;
  background-color: #2b2b2b;
}

.coming-soon {
  font-weight: 400;
  color: #bbb !important;
}
</style>

<script>
import router from "../../router";
import store from "../../store";
import axios from "axios";
import { apiBaseUrl } from "../../services/api";
import headerBuilder from "../../services/headerBuilder";
import LoaderMixin from "../../mixins/LoaderMixin";

export default {
  name: "DownloadNugget",
  mixins: [LoaderMixin()],
  data() {
    return {};
  },
  async created() {
    if (!store.state.audioNugget || !store.state.audioNugget.finalReference) {
      await this.computeNugget();
    }
  },
  methods: {
    async computeNugget() {
      await this.loaderify(async () => {
        const managedMediaResponse = await axios.post(
          `${apiBaseUrl}/api/AudioNugget/OverlayAudioNuggetOnVideo`,
          {
            AudioNuggetTemplateIdentifier:
              store.state.audioNugget.templateIdentifier,
            AvatarMediaReference: store.state.audioNugget.avatarReference,
            AudioMediaReference: store.state.audioNugget.audioReference,
            TitleText: store.state.audioNugget.titleText,
            BottomSmallText: store.state.audioNugget.bottomText,
          },
          {
            headers: headerBuilder.build(),
          }
        );
        const mediaReference = managedMediaResponse.data.mediaReference;
        console.log("Media Reference for Audio Nugget Final: ", mediaReference);
        store.commit("mergeAudioNugget", {
          finalReference: mediaReference,
        });
      });
    },
    encodePseudo64(str) {
      return btoa(str).replace(/[+]/g, "-").replace(/[/]/g, "_");
    },
    async finishNugget() {
      await this.downloadNugget();
      store.commit("setAudioNugget", null);
      router.push({
        name: "Home",
      });
    },
    async downloadNugget() {
      if (!store.state.audioNugget || !store.state.audioNugget.finalReference) {
        console.error("Referência não encontrada!");
        return;
      }
      const filename = store.state.audioNugget.finalReference + ".mp4";
      const downloadUrl = `${apiBaseUrl}/api/ManagedMedia/DownloadManagedMedia?MediaReference=${encodeURIComponent(
        store.state.audioNugget.finalReference
      )}&Authorization=${encodeURIComponent(
        this.encodePseudo64("Bearer " + store.state.token.bearer)
      )}&BrowserForcedDownload=true&ForceFilename=${encodeURIComponent(
        filename
      )}`;
      let a = document.createElement("a");
      a.href = downloadUrl;
      a.download = filename;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>
